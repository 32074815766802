import React, { useState, useEffect, } from 'react'
import { useSelector, useDispatch, } from 'react-redux'
// HashRouter BrowserRouter
import { HashRouter as Router, Switch, Route, useLocation } from 'react-router-dom'
import { Toast, } from 'antd-mobile'
import axios from 'axios'
import usePrevious from '@/hooks/usePrevious'
import Auth from './routers/Auth'
import PullLoading from '@/components_base/PullLoading'


// import DeviceProvider from '@/layouts/DeviceProvider'
import '@/scss/base.scss'
// import '@/mocks/index.js'

import { setOauthCode, setOauthOpenID, } from '@/redux/wechat/action'

import CONFIG from './config/global'
import { saveCookie, getCookie } from './utils/storage'
import { getQueryBySearch, getSearchByHash } from './utils/core/url'
import { getWeChatCodeUrl, getWeChatOpenID, } from './utils/wechat'

document.title = "三网融合管理系统"; //头部title
function App() {
  var dispatch = useDispatch()
  //
  const _urlSearch = window.location.search || getSearchByHash(window.location.hash)
  const _urlSearchHash = window.location.hash && getQueryBySearch(getSearchByHash(window.location.hash));
  //console.log(_urlSearch)
  const { code } = _urlSearch ? getQueryBySearch(_urlSearch) : {}
  const type = _urlSearch["type"] || _urlSearchHash["type"];

  //
  const { oauth_code, oauth_openid, } = useSelector(state => state.wechat)

  // console.log(document.location);
  // console.log(window.location.href.indexOf("#/authbind") != -1);
  const isauthbind = window.location.href.indexOf("#/authbind") != -1;
  //
  // const [oauthCodeState, setOauthCodeState] = useState(oauth_code)
  const [oauthCodeState, setOauthCodeState] = useState(null)
  const prevOauthCodeState = usePrevious(oauthCodeState)
  //
  // const [oauthOpenIDState, setOauthOpenIDState] = useState(oauth_openid)
  const [oauthOpenIDState, setOauthOpenIDState] = useState(null)
  const prevOauthOpenIDState = usePrevious(oauthOpenIDState)
  var ua = window.navigator.userAgent.toLowerCase();
  const uuu = ua.match(/MicroMessenger/i) == 'micromessenger';
  /**
   * *****************************************************************
   * *****************************************************************
   * 订阅
   * *****************************************************************
   * *****************************************************************
   */
  // 获取授权code链接
  useEffect(() => {
    console.log(code,uuu,isauthbind)
    console.log(window.location)
    if (!code && uuu && !isauthbind) {
      const { search, hash, } = window.location
      console.log(window.location)
      if (type != 1) {
        Toast.show('授权申请')
        let _hash = hash || '#/'
        let _url = `${search}${_hash}`
        getWeChatCodeUrl(
          { url: _url, },
          (url) => {
            console.log(`授权申请：${url}`)
            window.location.href = url
          },
          () => { },
        )

      }
      else {

        //点击授权

      }
    }
  }, [])
  //
  useEffect(() => {
    if (!oauthCodeState && code) {
      setOauthCodeState(code)
      // console.log('code')
    }
  }, [oauthCodeState, code])
  //
  useEffect(() => {
    const { search, hash, } = window.location
    if (oauthCodeState && !oauthOpenIDState && uuu && !isauthbind) {
      if (type != 1) {
        Toast.show('授权确认')
        getWeChatOpenID(
          { code: oauthCodeState, },
          (openid) => {
            console.log(openid)
            setOauthOpenIDState(openid)
          },
          () => { },
        )
      }
      else {
        //手动授权

      }
    }

  }, [oauthCodeState, oauthOpenIDState,])

  // 这里一次性处理redux状态
  useEffect(() => {
    // if (oauthCodeState !== prevOauthOpenIDState) {
    if (oauthOpenIDState !== prevOauthOpenIDState) {
      dispatch(setOauthCode(oauthCodeState))
      dispatch(setOauthOpenID(oauthOpenIDState))
      // 缓冲0.5h，以天为单位
      const _opt = {
        expires: 1
      }
      saveCookie(CONFIG['oauth'], JSON.stringify(Object.assign({}, {
        // 'oauth_code': oauthCodeState,
        'oauth_openid': oauthOpenIDState,
      })), _opt)
      //
      axios.defaults.headers.common['x-openid'] = oauthOpenIDState
      // 消除href-code
      // redirectForCode()

      // console.log('********')
      // console.log(oauthCodeState)
      // console.log(prevOauthOpenIDState)
      // setTimeout(() => {
      redirectForCode()
      // }, 100)

    }
  }, [oauthCodeState, prevOauthOpenIDState, oauthOpenIDState])



  /**
   * *****************************************************************
   * *****************************************************************
   * 回调
   * *****************************************************************
   * *****************************************************************
   */
  // 重定向清除code
  function redirectForCode() {
    const { origin, pathname, hash, search, } = document.location
    // console.log(`${pathname}${hash}`)
    document.location.href = `${pathname}${search}${hash}`

  }
  /**
   * *****************************************************************
   * *****************************************************************
   * 渲染
   * *****************************************************************
   * *****************************************************************
   */
  return (
    <React.Fragment>

      {/* <Router>
          <Auth />
        </Router> */}
      {/* {!oauthOpenIDState && <PullLoading />}
      {oauthOpenIDState && (
        <Router>
          <Auth />
        </Router>
      )} */}
      <Router>
        <Auth />
      </Router>

    </React.Fragment>
  )
}

export default App

